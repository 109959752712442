import React from "react";
import styles from "./MapOverlay.module.scss";
import MapCategoryToggle from "./MapCategoryToggle";
import { useState } from "react";
import { cn } from "../../lib/helpers";
import Icon from "../icons/Icon";

const MapOverlay = ({ categories, setFilter, selectedCategory }) => {
  const [open, setOpen] = useState(false);

  const toggleOverlay = () => {
    setOpen(!open);
  };

  return (
    <div className={cn(styles.mapOverlay, open && styles.open)}>
      <h2 onClick={toggleOverlay}>Explore the area</h2>
      <div className={styles.close} onClick={toggleOverlay}>
        <Icon
          symbol={open ? "close" : "filter"}
          fill={open ? "var(--color-white)" : false}
          size="32"
        />
      </div>
      <div className={styles.toggles}>
        <MapCategoryToggle
          name={{ en: "Landmarks", nb: "Landemerker" }}
          color="var(--color-red)"
          onClick={() => setFilter("landmarks")}
          selected={selectedCategory === "landmarks"}
        />
        {categories.map(({ name, color }, index) => (
          <MapCategoryToggle
            key={`map-category-${index}`}
            name={name}
            color={color}
            selected={selectedCategory === name.en.toLowerCase()}
            onClick={() => setFilter(name.en.toLowerCase())}
          />
        ))}
      </div>
    </div>
  );
};

export default MapOverlay;
