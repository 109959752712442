import React, { useState } from "react";
import { useStaticQuery } from "gatsby";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import Nav from "../components/Nav/Nav";
import Pagination from "../components/Pagination/Pagination";
import SectionsContainer from "../components/SectionsContainer/SectionsContainer";
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import ComingSoon from "../components/ComingSoon/ComingSoon";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      sanityCityGuideSettings(_id: { regex: "/cityGuideSettings$/" }) {
        socialTitle
        socialDescription
        socialSharingImage {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
        categories {
          color
          name {
            en
            nb
          }
        }
      }
      allSanityCityGuideSection {
        edges {
          node {
            text: _rawText
            listTitle: _rawListTitle
            images {
              asset {
                fluid(maxWidth: 700) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            category: _rawCategory(resolveReferences: { maxDepth: 1 })
            title: _rawTitle
            curatedContent {
              category: _rawCategory(resolveReferences: { maxDepth: 1 })
              location: _rawLocation
              name
              address
              link
              text: _rawText(resolveReferences: { maxDepth: 1 })
              image {
                asset {
                  fluid(maxWidth: 600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
      allSanityCityGuideItem {
        edges {
          node {
            category: _rawCategory(resolveReferences: { maxDepth: 1 })
            location: _rawLocation
            name
            address
            link
            text: _rawText(resolveReferences: { maxDepth: 1 })
          }
        }
      }
      allSanityCompany(
        filter: { _id: { regex: "/^((?!draft).)*$/" }, cityGuide: { eq: true } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            category: _rawCategory(resolveReferences: { maxDepth: 1 })
            location: _rawLocation
            companyInformation {
              information {
                city
                postalCode
                street
              }
            }
            bookingLink
          }
        }
      }
    }
  `);
  const location = useLocation();

  const [selectedSection, setSelectedSection] = useState(null);
  const [topSection, setTopSection] = useState(null);
  const {
    socialSharingImage,
    socialTitle,
    socialDescription,
  } = data.sanityCityGuideSettings;
  const categories = data.sanityCityGuideSettings.categories || [];
  const sections = data.allSanityCityGuideSection.edges;
  let items = data.allSanityCityGuideItem.edges;

  const matchCategory = (categoryName) => {
    categoryName = categoryName.includes("ealth") ? "Activities" : categoryName;
    return categories.find((category) =>
      categoryName.toLowerCase().includes(category.name.en.toLowerCase())
    );
  };
  const companies = data.allSanityCompany.edges
    .filter(({ node: company }) => company.companyInformation)
    .map(({ node: company }) => {
      const {
        street,
        postalCode,
        city,
      } = company.companyInformation.information;
      return {
        node: {
          category: company.category
            ? matchCategory(company.category.name.en)
            : null,
          location: company.location,
          name: company.name,
          address: `${street} ${postalCode} ${city}`,
          link: street === null ? company.bookingLink : null,
        },
      };
    })
    .filter(({ node: company }) => company.category);

  items = items.concat(companies);

  useEffect(() => {
    const index = categories
      .map((category) => category.name.en.toLowerCase())
      .indexOf(location.hash.split("#")[1]);
    changeSection(index >= 0 ? index : null);
  }, [location]);

  const changeSection = (newSection) => {
    if (newSection !== selectedSection) {
      setSelectedSection(null);
      if (selectedSection !== null) {
        setTimeout(() => {
          setTopSection(newSection);
          setSelectedSection(newSection);
        }, 600);
      } else {
        setTopSection(newSection);
        setSelectedSection(newSection);
      }
    }
  };

  const sortedSections = [];
  categories.forEach((category, index) => {
    const section = sections.find(
      ({ node: section }) => section.category.name.en === category.name.en
    );
    section && sortedSections.push(section);
  });
  return (
    <>
      <Layout>
        <SEO
          title={socialTitle}
          description={socialDescription}
          ogImage={socialSharingImage.asset.fixed.src}
        />
        <Nav categories={categories} setSelectedSection={changeSection} />
        <Pagination
          categories={categories}
          selectedSection={selectedSection}
          onClick={changeSection}
          top={topSection}
          sections={sortedSections}
        />
        <SectionsContainer
          sections={sortedSections}
          selectedSection={selectedSection}
          items={items}
          mapCategory={categories.find(
            (category) => category.name.en === "Map"
          )}
          categories={categories}
        />
      </Layout>
    </>
  );
};

export default IndexPage;
