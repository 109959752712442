import React from "react";
import styles from "./GuidedContent.module.scss";
import { getLink } from "../../lib/helpers";
import Img from "gatsby-image";
import Icon from "../icons/Icon";
import BlockContentWrapper from "../BlockContent/BlockContent";

const GuidedContent = ({ curatedContent }) => {
  return (
    <div className={styles.guidedContent}>
      {curatedContent.map((curatedItem, index) => {
        const link = getLink(
          curatedItem.name,
          curatedItem.address,
          curatedItem.link,
          curatedItem.location
        );
        return (
          <div className={styles.curatedItem} key={`guided-content-${index}`}>
            <div className={styles.text}>
              <h3>{curatedItem.name}</h3>
              <BlockContentWrapper blocks={curatedItem.text.nb} />
              {link && (
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.text}{" "}
                  <Icon
                    symbol="arrowForward"
                    viewBox="0 0 36 24"
                    size="24"
                    strokeWidth={2}
                  />
                </a>
              )}
            </div>
            {curatedItem.image && <Img fluid={curatedItem.image.asset.fluid} />}
          </div>
        );
      })}
    </div>
  );
};

export default GuidedContent;
