import React from "react";
import { luminance, cn } from "../../lib/helpers";
import styles from "./Section.module.scss";
import { useRef } from "react";
import { useEffect } from "react";
import Footer from "../Footer/Footer";

const SectionWrapper = ({
  children,
  color,
  open,
  noPadding,
  withoutFooter,
}) => {
  const textColor = luminance(color) > 0.3 ? "#000" : "#fff";
  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, [open]);

  return (
    <div
      className={cn(styles.section, open && styles.open)}
      style={{ color: textColor }}
    >
      <div
        ref={scrollRef}
        className={styles.sectionContentWrapper}
        id="scrollContainer"
      >
        <div className={cn(styles.content, noPadding && styles.noPadding)}>
          {children}
        </div>
        {!withoutFooter && <Footer />}
      </div>
    </div>
  );
};

SectionWrapper.defaultProps = {
  noPadding: false,
};

export default SectionWrapper;
