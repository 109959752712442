const React = require("react");
const BlockContent = require("@sanity/block-content-to-react");

const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const BlockContentWrapper = (props) => {
  const { blocks } = props;
  return (
    <div {...props}>
      <BlockContent blocks={blocks} serializers={serializers} />
    </div>
  );
};

export default BlockContentWrapper;
