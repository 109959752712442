import React from "react";
import Icon from "../icons/Icon";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <h2>
        Opplev noe nytt i sommer!
        <br />
        Oslobukta, midt i Bjørvika
      </h2>
      <div>
        <p>Discover even more</p>
        <a href="https://oslobukta.no">
          oslobukta.no{" "}
          <Icon
            symbol="arrowForward"
            viewBox="0 0 36 24"
            size="24px"
            strokeWidth={2}
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
