import React from "react";
import BlockContentWrapper from "../BlockContent/BlockContent";
import styles from "./Section.module.scss";
import ListItem from "../ListItem/ListItem";
import SectionWrapper from "./SectionWrapper";
import GuidedContent from "../GuidedContent/GuidedContent";
import { LanguageContextConsumer } from "../../contexts/languageContext";

const Section = ({
  title,
  text,
  curatedContent,
  category,
  items,
  listTitle,
  open,
}) => {
  return (
    <SectionWrapper color={category.color} open={open}>
      <LanguageContextConsumer>
        {({ localize }) => (
          <>
            <div className={styles.text}>
              <h2 data-aos="fade-up">{localize(title)}</h2>
              <BlockContentWrapper
                blocks={text ? localize(text) : []}
                data-aos="fade-up"
              />
            </div>
            <GuidedContent curatedContent={curatedContent || []} />
            <h3 className={styles.listTitle}>{localize(listTitle)}</h3>
            <ul className={styles.list}>
              {items.map(({ node: item }, index) => (
                <ListItem
                  name={item.name}
                  key={`section-${index}`}
                  address={item.address}
                  location={item.location}
                />
              ))}
            </ul>
          </>
        )}
      </LanguageContextConsumer>
    </SectionWrapper>
  );
};

export default Section;
