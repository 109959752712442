import React from "react";
import PropTypes from "prop-types";
import styles from "./RepeatingText.module.scss";

const RepeatingText = ({ children, repeats }) => {
  const headings = [];
  for (let i = 0; i < repeats; i++) {
    headings.push({ text: children, index: i + 1 });
  }
  return (
    <div className={styles.container}>
      {headings.map((heading) => (
        <h1 style={{ zIndex: heading.index }} className={styles.text}>
          {heading.text}
        </h1>
      ))}
    </div>
  );
};

RepeatingText.defaultProps = {
  repeats: 5,
};

RepeatingText.propTypes = {
  children: PropTypes.string.isRequired,
  repeats: PropTypes.number.isRequired,
};

export default RepeatingText;
