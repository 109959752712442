import React from "react";
import barcode from "../../staticfiles/img/Barcode.png";
import dive from "../../staticfiles/img/Dive.png";
import munchMuseum from "../../staticfiles/img/MunchMuseum.png";
import opera from "../../staticfiles/img/Opera.png";
import { Marker } from "react-map-gl";
import styles from "./Map.module.scss";

const Landmarks = ({ onClick, zoom }) => {
  const landmarks = [
    {
      img: barcode,
      location: { lng: 10.759136, lat: 59.908153 },
      alt: "Barcode icon",
      zoomConstant: 1,
      data: {
        name: "Barcode",
        address: " ",
        pinColor: "#000",
        location: { lng: 10.759136, lat: 59.908153 },
      },
    },
    {
      img: opera,
      location: { lng: 10.752371, lat: 59.907419 },
      alt: "Opera icon",
      zoomConstant: 128 / 228,
      data: {
        name: "Operahuset",
        address: "Oslo",
        pinColor: "#000",
        location: { lng: 10.752371, lat: 59.907419 },
      },
    },
    {
      img: munchMuseum,
      location: { lng: 10.75515, lat: 59.905746 },
      alt: "Munch icon",
      zoomConstant: 60 / 228,
      data: {
        name: "Munch",
        address: "Lambda Oslo",
        pinColor: "#000",
        location: { lng: 10.75515, lat: 59.905746 },
      },
    },
    {
      img: dive,
      location: { lng: 10.751201, lat: 59.900627 },
      zoomConstant: 0.263157895,
      alt: "Dive icon",
      data: {
        name: "Sørenga Sjøbad",
        address: "Sørengkaia Oslo",
        pinColor: "#000",
        location: { lng: 10.751201, lat: 59.900627 },
      },
    },
  ];
  const zoomFunction = (x) => 0.0186 * Math.pow(Math.E, 0.6484 * x);

  return landmarks.map((landmark) => (
    <Marker
      key={landmark.alt}
      longitude={landmark.location.lng}
      latitude={landmark.location.lat}
      className={styles.landmark}
      anchor="center"
    >
      <img
        style={{ width: zoomFunction(zoom) * landmark.zoomConstant }}
        src={landmark.img}
        alt={landmark.alt}
        onClick={() => onClick(landmark.data)}
      />
    </Marker>
  ));
};

export default Landmarks;
