import React, { useState } from "react";
import PropTypes from "prop-types";
import Mapbox from "./Mapbox";
import Pin from "./Pin";
import MapOverlay from "../MapOverlay/MapOverlay";
import Landmarks from "./Landmarks";
import Popup from "./Popup";

const Map = ({
  width,
  height,
  scroll,
  pinData,
  center,
  withPopup,
  categories,
}) => {
  const [item, setItem] = useState();
  const [zoom, setZoom] = useState(11);
  const [popupInfo, setPopupInfo] = useState();
  const [selectedPinData, setSelectedPinData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("landmarks");
  // pinData = categories ? colorizePins(pinData, categories) : pinData;

  const _onClickMarker = (item) => {
    setItem(item);
    setPopupInfo(true);
  };

  const setFilter = (filter) => {
    setPopupInfo(false);
    setSelectedPinData(
      pinData.filter((pin) => pin.categoryName.includes(filter))
    );
    setSelectedCategory(filter);
  };

  return (
    <Mapbox
      width={width}
      height={height}
      scroll={scroll}
      center={center}
      setPopupInfo={setPopupInfo}
      setZoom={setZoom}
    >
      {selectedPinData &&
        selectedPinData.map((pin, index) => (
          <Pin
            onClick={withPopup && _onClickMarker}
            key={`marker-${index}`}
            data={pin}
          />
        ))}
      {selectedCategory === "landmarks" && (
        <Landmarks onClick={withPopup && _onClickMarker} zoom={zoom} />
      )}
      {popupInfo && <Popup item={item} setPopupInfo={setPopupInfo} />}
      <MapOverlay
        categories={categories}
        setFilter={setFilter}
        selectedCategory={selectedCategory}
      />
    </Mapbox>
  );
};

Map.defaultProps = {
  width: "100vw",
  height: "80vh",
  scroll: false,
};

Map.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  scroll: PropTypes.bool,
  pinData: PropTypes.array,
  center: PropTypes.object,
  withPopup: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.object),
};

export default Map;
