import React from "react";
import Number from "./Number";
import styles from "./Pagination.module.scss";

const Pagination = ({
  categories,
  selectedSection,
  onClick,
  top,
  sections,
}) => {
  return (
    <div className={styles.pagination}>
      {categories.map((category, index) => (
        <Number
          key={`number-${index}`}
          number={index + 1}
          color={category.color}
          open={selectedSection === index}
          top={top === index}
          name={category.name.en.toLowerCase()}
          onClick={onClick}
          images={
            sections
              .filter(
                ({ node: section }) =>
                  section.category.name.en.toLowerCase() ===
                  category.name.en.toLowerCase()
              )
              .map(({ node: section }) => section.images)[0]
          }
        />
      ))}
    </div>
  );
};

export default Pagination;
