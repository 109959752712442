import React from "react";
import iconLibrary from "./iconlibrary";
import PropTypes from "prop-types";

function whichIcon(symbol) {
  if (Object.getOwnPropertyNames(iconLibrary).includes(symbol)) {
    return iconLibrary[symbol];
  }
}

function Icon({ symbol, fill, strokeWidth, size, color, viewBox, className }) {
  const iconStyle = {
    width: size,
    height: size,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || "0 0 24 24"}
      fill={fill ? color : "none"}
      shapeRendering="geometricPrecision"
      size={size}
      style={iconStyle}
      className={className}
    >
      <g stroke={fill ? "none" : color} strokeWidth={strokeWidth}>
        {whichIcon(symbol)}
      </g>
    </svg>
  );
}

Icon.defaultProps = {
  strokeWidth: 1,
  size: "32px",
  color: "currentColor",
};

Icon.propTypes = {
  symbol: PropTypes.string.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.string,
  fill: PropTypes.bool,
};

export default Icon;
