import React from "react";

const iconLibrary = {
  arrowBack: (
    <>
      <path d="M10.552 6L3 12m7.552 6L3 12m0 0h18" />
    </>
  ),
  arrowForward: (
    <>
      <path d="M1 12.5h34" />
      <path d="M24.777 1.357l10.866 10.842-11.47 11.444" />
    </>
  ),
  facebook: (
    <>
      <path d="M22.663 0c.731 0 1.324.593 1.324 1.324v21.34c0 .73-.593 1.323-1.324 1.323h-6.112v-9.289h3.118l.467-3.62H16.55V8.767c0-1.048.29-1.763 1.794-1.763h1.917V3.765c-.332-.044-1.47-.143-2.794-.143-2.764 0-4.656 1.687-4.656 4.785v2.67H9.686v3.62h3.126v9.29H1.324A1.324 1.324 0 010 22.662V1.323C0 .594.593 0 1.324 0h21.34z" />
    </>
  ),
  instagram: (
    <>
      <path d="M17.5 0h-11A6.51 6.51 0 000 6.5v11A6.51 6.51 0 006.5 24h11a6.51 6.51 0 006.5-6.5v-11A6.51 6.51 0 0017.5 0zM12 17.5a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm6.5-11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" />
    </>
  ),
  linkedin: (
    <>
      <path d="M6.5 8.5H2.5C2.22386 8.5 2 8.72386 2 9V22C2 22.2761 2.22386 22.5 2.5 22.5H6.5C6.77614 22.5 7 22.2761 7 22V9C7 8.72386 6.77614 8.5 6.5 8.5Z" />
      <path d="M4.48 6.5C5.84967 6.5 6.96 5.38071 6.96 4C6.96 2.61929 5.84967 1.5 4.48 1.5C3.11033 1.5 2 2.61929 2 4C2 5.38071 3.11033 6.5 4.48 6.5Z" />
      <path d="M18.5 22.5H21.5C21.6326 22.5 21.7598 22.4473 21.8536 22.3536C21.9473 22.2598 22 22.1326 22 22V13.6C22 9.83001 19.87 8.00001 16.89 8.00001C16.3022 7.97851 15.7164 8.08042 15.1704 8.29916C14.6245 8.5179 14.1304 8.84862 13.72 9.27001C13.6652 9.33333 13.5923 9.37835 13.5111 9.39901C13.43 9.41967 13.3445 9.41498 13.266 9.38558C13.1876 9.35617 13.1201 9.30346 13.0725 9.23453C13.025 9.1656 12.9997 9.08375 13 9.00001C13 8.8674 12.9473 8.74022 12.8536 8.64645C12.7598 8.55268 12.6326 8.50001 12.5 8.50001H9.5C9.36739 8.50001 9.24021 8.55268 9.14645 8.64645C9.05268 8.74022 9 8.8674 9 9.00001V22C9 22.1326 9.05268 22.2598 9.14645 22.3536C9.24021 22.4473 9.36739 22.5 9.5 22.5H12.5C12.6326 22.5 12.7598 22.4473 12.8536 22.3536C12.9473 22.2598 13 22.1326 13 22V14.5C13 13.837 13.2634 13.2011 13.7322 12.7322C14.2011 12.2634 14.837 12 15.5 12C16.163 12 16.7989 12.2634 17.2678 12.7322C17.7366 13.2011 18 13.837 18 14.5V22C18 22.1326 18.0527 22.2598 18.1464 22.3536C18.2402 22.4473 18.3674 22.5 18.5 22.5Z" />
    </>
  ),
  calendar: (
    <>
      <path d="M21.558 5.61h-3.186v.895h2.743v2.327H2.885V6.505h3.894v1.61h.885V4h-.885v1.61H2.442L2 6.059v14.495l.442.447h19.116l.442-.447V6.058l-.442-.447zM2.885 20.106V9.726h18.23v10.38H2.885zm13.451-11.99h.885V4h-.885v1.61H8.814v.895h7.522v1.61z" />
    </>
  ),
  news: (
    <>
      <path d="M21.474 4h-1.895l-.169.02L3.053 9.404V8.26H2v7.48h1.053v-1.143l4.631 1.517v1.496l.358.5 5.684 1.87.695-.5v-1.142l4.99 1.641.168.021h1.895l.526-.52V4.52L21.474 4zm-8.106 14.753l-4.631-1.517v-.768l4.631 1.516v.77zm7.58.208h-1.285l-16.61-5.465v-2.992l16.61-5.465h1.284v13.922z" />
    </>
  ),
  info: (
    <>
      <path d="M12 22C6.484 22 2 17.516 2 12S6.484 2 12 2s10 4.484 10 10-4.484 10-10 10zm0-18.947c-4.926 0-8.947 4.02-8.947 8.947 0 4.926 4.02 8.947 8.947 8.947 4.926 0 8.947-4.02 8.947-8.947 0-4.926-4.02-8.947-8.947-8.947zm0 3.536c.379 0 .674.295.674.674a.665.665 0 01-.674.674.665.665 0 01-.674-.674c0-.379.295-.674.674-.674zm-.526 3.243h1.052v7.578h-1.052V9.832z" />
    </>
  ),
  search: (
    <>
      <path
        clipRule="evenodd"
        d="M16.54 20.802a8.056 8.056 0 10-6.302-14.828 8.056 8.056 0 006.302 14.828z"
      />
      <path d="M19.086 19.086l6.248 6.249" />
    </>
  ),
  circleClose: (
    <>
      <circle cx="12" cy="12" r="11" />
      <path d="M6.25 6.5L17.75 18m0-11.5L6.25 18" />
    </>
  ),
  close: (
    <>
      <path d="M6.944 5.5L18.5 17.056 17.056 18.5 5.5 6.944 6.944 5.5z" />
      <path d="M18.5 6.944L6.944 18.5 5.5 17.056 17.056 5.5 18.5 6.944z" />
    </>
  ),
  hamburger: (
    <>
      <path d="M4 6.855h16v1.959H4zM4 10.773h16v1.959H4zM4 14.691h16v1.959H4z" />
    </>
  ),
  file: (
    <>
      <path d="M19.625 7.825V21H5V3h9.8l4.825 4.825zm-4.5-.325H17.7l-2.575-2.575V7.5zM18.5 19.875V8.625H14v-4.5H6.125v15.75H18.5z" />
    </>
  ),
  filter: (
    <>
      <path
        clipRule="evenodd"
        d="M5 4h14.492l-5.583 7.954v7.033L10.584 21v-9.046L5 4z"
      />
    </>
  ),
};

export default iconLibrary;
