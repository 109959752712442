import React from "react";
import styles from "./Map.module.scss";
import { Popup as MapboxPopup } from "react-map-gl";
import { getLink } from "../../lib/helpers";
import Icon from "../icons/Icon";

const Popup = ({ item, setPopupInfo }) => {
  const link = getLink(item.name, item.address, item.link, item.location);
  return (
    <MapboxPopup
      longitude={item.location.lng}
      latitude={item.location.lat}
      className={styles.popup}
      offsetTop={-35}
    >
      <a
        className={styles.innerPoup}
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          backgroundColor: item.pinColor,
        }}
      >
        <span>{item.name}</span>
        {link.url && <Icon symbol="arrowForward" viewBox="0 0 36 24" />}
      </a>
    </MapboxPopup>
  );
};

export default Popup;
