import React from "react";
import PropTypes from "prop-types";
import styles from "./ListItem.module.scss";
import { mapLink, mapCoordsLink, getLink } from "../../lib/helpers";
import Icon from "../icons/Icon";

const ListItem = ({ name, location, color, address, link }) => {
  const hyperlink = getLink(name, address, link, location);
  return address || link || location ? (
    <li className={styles.listItem} data-aos-fade>
      <a
        href={hyperlink.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: color }}
      >
        <div>
          <p>{name}</p>
          <p>{hyperlink.text}</p>
        </div>
        <Icon symbol="arrowForward" viewBox="0 0 36 24" />
      </a>
    </li>
  ) : null;
};

export default ListItem;
