import React, { useState, useEffect } from "react";
import styles from "./Pagination.module.scss";
import { cn } from "../../lib/helpers";
import Img from "gatsby-image";

const Number = ({ number, name, color, open, onClick, top, images }) => {
  const [selectedImage, setSelectedImage] = useState(
    images && images.length > 0 ? images[0] : false
  );
  useEffect(() => {
    images &&
      setSelectedImage(images[Math.floor(Math.random() * images.length)]);
  }, []);
  return (
    <a
      href={`#${name}`}
      className={cn(styles.number, open && styles.open, top && styles.top)}
      aria-label={name}
    >
      <div className={styles.background} style={{ backgroundColor: color }} />
      <div
        className={styles.content}
        data-desktop={`0${number}.`}
        data-mobile={name}
      />
      {selectedImage && (
        <div className={styles.hoverImage}>
          <Img fluid={selectedImage.asset.fluid} />
        </div>
      )}
    </a>
  );
};

export default Number;
