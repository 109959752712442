import React from "react";
import Section from "../Section/Section";
import MapSection from "../Section/MapSection";

const SectionsContainer = ({
  sections,
  items,
  mapCategory,
  selectedSection,
  categories,
}) => {
  return (
    <>
      <MapSection
        items={items}
        categories={categories}
        open={sections.length - 1 === selectedSection}
        category={mapCategory}
      />
      {sections
        .filter(
          ({ node: section }) =>
            !section.category.name.en.toLowerCase().includes("map")
        )
        .map(({ node: section }, index) => (
          <Section
            open={index === selectedSection}
            {...section}
            key={`Section-${index}`}
            items={items.filter(
              ({ node: item }) =>
                item.category.name.en === section.category.name.en
            )}
          />
        ))}
    </>
  );
};

export default SectionsContainer;
