import React from "react";
import PropTypes from "prop-types";
import styles from "./MapOverlay.module.scss";
import { cn } from "../../lib/helpers";

const MapCategoryToggle = ({ name, color, onClick, selected }) => {
  return (
    <div
      className={cn(styles.mapToggle, selected && styles.selected)}
      onClick={onClick}
    >
      <div className={styles.circle} style={{ backgroundColor: color }} />
      <span>{name.en}</span>
    </div>
  );
};

export default MapCategoryToggle;
