import React from "react";
import PropTypes from "prop-types";
import styles from "./Section.module.scss";
import SectionWrapper from "./SectionWrapper";
import Map from "../Map/Map";

const MapSection = ({ category, categories, open, items }) => {
  const pinData = items.map(({ node: item }) => ({
    name: item.name,
    categoryName: item.category.name.en.toLowerCase(),
    location: item.location,
    pinColor: item.category.color,
    address: item.address,
    link: item.link,
  }));

  return (
    <SectionWrapper color={category.color} open={open} noPadding withoutFooter>
      <Map
        height={`calc(100vh - var(--nav-height))`}
        width="100vw"
        pinData={pinData}
        scroll
        categories={categories.filter(
          (category) => !category.name.en.toLowerCase().includes("map")
        )}
        withPopup
      />
    </SectionWrapper>
  );
};

export default MapSection;
