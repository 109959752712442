function hexToRgb(hex) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const luminance = (hex) => {
  var a = Object.values(hexToRgb(hex)).map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const cn = (...classes) => classes.join(" ");

export const mapLink = (name, address) => {
  const linkPart = [];
  name && linkPart.push(name.replace(" ", "+"));
  address && linkPart.push(address.replace(" ", "+"));
  return `https://www.google.com/maps/search/?api=1&query=${linkPart.join(
    "%2C"
  )}`;
};

export const mapCoordsLink = ({ lng, lat }) =>
  `http://www.google.com/maps/place/${lat},${lng}`;

export const getLink = (name, address, link, location) => {
  if (address || link || location) {
    return {
      url: address
        ? mapLink(name, address)
        : link
        ? link
        : mapCoordsLink(location),
      text:
        address || (!link && location)
          ? "Åpne i Google maps"
          : "Åpne nettsiden",
    };
  }
  return null;
};
