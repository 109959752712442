import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactMapGL, { NavigationControl } from "react-map-gl";
import { useEffect } from "react";

const mapboxApiAccessToken =
  process.env.MAPBOX_TOKEN ||
  "pk.eyJ1Ijoib3Nsb2J1a3RhIiwiYSI6ImNrOWUwaWxoMzA5bW8zcm1sd2prc3o1eGwifQ.vmADo8p6hG4B1eTw0R6aTA";

const Mapbox = ({
  width,
  height,
  scroll,
  children,
  center,
  setPopupInfo,
  setZoom,
}) => {
  const [viewport, setViewport] = useState({
    width: width || 400,
    height: height || 400,
    latitude: (center && center.lat) || 59.905,
    longitude: (center && center.lng) || 10.759,
    bearing: 26,
    zoom: 13.28,
    minZoom: 12,
    maxZoom: 17,
    mapboxApiAccessToken,
  });
  useEffect(() => {
    const updateMapWidth = () => {
      setViewport({ ...viewport, width, height });
    };
    window && window.addEventListener("resize", updateMapWidth);
    return () => window.removeEventListener("resize", updateMapWidth);
  }, []);

  return (
    <ReactMapGL
      {...viewport}
      onViewportChange={setViewport}
      onViewStateChange={(e) => setZoom(e.viewState.zoom)}
      mapStyle="mapbox://styles/oslobukta/ckblxc4zh0hfi1jnzyad4jc0y"
      scrollZoom={scroll}
      attributionControl={false}
      onClick={() => setPopupInfo(false)}
    >
      {!scroll && (
        <div style={{ position: "absolute", right: 0 }}>
          <NavigationControl />
        </div>
      )}
      {children}
    </ReactMapGL>
  );
};

Mapbox.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  scroll: PropTypes.bool,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  children: PropTypes.any,
};

export default Mapbox;
